import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Hey there!`}</h3>
    <p>{`Natalie Davidson is a kitesurfer, kitefoiler and information systems professional from South Carolina. 🤓
She has worked as an engineer and team lead in software product development and operations.`}</p>
    <iframe align="top" name="wind-map" id="wind-map" src="https://widgets.windalert.com/widgets/web/windMap?w=600&h=450&c=fbd5f0&rp=18&m_m=t&csn=Isle of Palms&search=82.5,-62.33333&sn=Alert&sid=67554&u_t=F&m_c=32.79,-79.79&m_z=12&app=windalert" width="600" height="450" frameBorder="0" scrolling="no" allowtransparency="no"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      